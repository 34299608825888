<template>
  <div>
    <navbar />
    <section class="blog-view">
      <div class="container pb-3 pt-2">
        <iframe
          id="msgsndr-calendar"
          src="https://link.hypermarketinglive.com/widget/booking/NciGwoWFZUcfFsnFNDi4"
          style="width: 100%;border:none;overflow: hidden;"
          scrolling="no"
        />
      </div>
    </section>

    <app-footer />
  </div>
</template>

<script>
import Navbar from './site-content/Navbar.vue'
import AppFooter from './site-content/AppFooter.vue'

export default {
  components: {
    Navbar,
    AppFooter,
  },
}
</script>
